import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import axios from "axios";
import { useIsMobile } from "hooks/useIsMobile";
import { noop } from "lodash";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useParams } from "react-router-dom";
import { Button, Icon } from "shared-components";
import { MediaPlanContext } from "../../context";
import { DateRange } from "../../types";
import { prepareMediaPlan } from "../../utils";
import { CalendarInfo } from "./CalendarInfo";
import "./SponsorshipRangeSelector.scss";

const MAX_DATE_RANGE = 7; // in days

export default function SponsorshipRangeSelector() {
  const { mediaPlanId } = useParams();
  const mediaPlanContext = useContext(MediaPlanContext)!;
  const { dateRange, setMediaPlan, isBooked } = mediaPlanContext;
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [tempRange, setTempRange] = useState<DateRange>(
    dateRange || { startDate: null, endDate: null }
  );
  const isMobile = useIsMobile();
  const [discountedDates, setDiscountedDates] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const fetchDiscountedDates = async () => {
      if (!mediaPlanId || !mediaPlanContext.profiles.length || !isPickerOpen) {
        return;
      }
      try {
        const today = moment().format("YYYY-MM-DD");
        const response = await axios.get(
          `/api/v1/advertiser/media_plans/${mediaPlanId}/dates_with_discounts`,
          {
            params: {
              from_date: today,
            },
          }
        );
        const formattedDates = response.data.map((dateStr: string) =>
          moment(dateStr).format("YYYY-MM-DD")
        );
        setDiscountedDates(new Set(formattedDates));
      } catch {
        noop();
      }
    };

    fetchDiscountedDates();
  }, [mediaPlanId, mediaPlanContext.profiles.length, isPickerOpen]);

  //listen for the Escape key
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closePicker();
      }
    };

    if (isPickerOpen) {
      document.addEventListener("keydown", handleEscKey);
    }

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [isPickerOpen]);

  const updateTempRange = (newStart: Moment | null, newEnd: Moment | null) => {
    if (newStart && newEnd) {
      const diff = newEnd.diff(newStart, "days");
      if (diff > MAX_DATE_RANGE) {
        return;
      }
    }

    setTempRange({ startDate: newStart, endDate: newEnd });
  };

  const resetTempRange = () => {
    setTempRange(dateRange || { startDate: null, endDate: null });
  };

  const closePicker = () => {
    setFocusedInput(null);
    setIsPickerOpen(false);
  };

  const handleCancel = () => {
    resetTempRange();
    closePicker();
  };

  const handleApply = async () => {
    const { startDate, endDate } = tempRange;
    if (startDate && endDate) {
      const diff = endDate.diff(startDate, "days");
      if (diff > MAX_DATE_RANGE) {
        return;
      }

      setLoading(true);
      try {
        const response = await axios.patch(
          `/api/v1/advertiser/media_plans/${mediaPlanId}`,
          null,
          {
            params: {
              from_date: startDate.format("YYYY-MM-DD"),
              to_date: endDate.format("YYYY-MM-DD"),
            },
          }
        );
        setMediaPlan(prepareMediaPlan(response.data));
        setLoading(false);
        closePicker();
      } catch {
        setLoading(false);
        noop();
      }
    }
  };

  const handleOpenPicker = () => {
    resetTempRange();
    setFocusedInput("startDate");
    setIsPickerOpen(true);
  };

  const handleFocusChange = (focused: FocusedInputShape | null) => {
    setFocusedInput(focused || "startDate");
  };

  const isOutsideRange = (day: Moment) => {
    const today = moment().startOf("day");
    const checkDay = day.clone().startOf("day");

    let outside = checkDay.isBefore(today.clone().add(2, "days"));
    outside = outside || checkDay.isAfter(today.clone().add(6, "months"));

    if (focusedInput === "endDate" && tempRange.startDate) {
      const startDay = tempRange.startDate.clone().startOf("day");

      outside =
        outside ||
        checkDay.isAfter(startDay.clone().add(MAX_DATE_RANGE, "days")) ||
        checkDay.isBefore(startDay);
    }

    return outside;
  };

  const renderDayContents = (day: Moment) => {
    const { startDate, endDate } = tempRange;
    const isBoundaryDay =
      (startDate && day.isSame(startDate, "day")) ||
      (endDate && day.isSame(endDate, "day"));

    return (
      <div className="day-container">
        <div className={isBoundaryDay ? "start-end-circle" : undefined}>
          {day.format("D")}
        </div>
        {discountedDates.has(day.format("YYYY-MM-DD")) && (
          <div className="discounted-dot" />
        )}
      </div>
    );
  };

  const navIcon = (isPrev: boolean) => (
    <Box
      sx={{
        position: "absolute",
        [isPrev ? "left" : "right"]: 16,
        top: 16,
        zIndex: 2,
      }}
    >
      <IconButton size="small" sx={{ color: "text.disabled" }}>
        {isPrev ? <KeyboardDoubleArrowLeft /> : <KeyboardDoubleArrowRight />}
      </IconButton>
    </Box>
  );

  const getButtonLabel = () =>
    dateRange?.startDate && dateRange?.endDate
      ? `${dateRange.startDate.format(
          "MMM Do, YYYY"
        )} - ${dateRange.endDate.format("MMM Do, YYYY")}`
      : "Select Sponsorship Period";

  return (
    <>
      <Button
        disabled={isBooked}
        variant="outlined"
        sx={{ flexGrow: 1 }}
        startIcon={<Icon name="calendarAddOn" fill="inherit" />}
        onClick={handleOpenPicker}
      >
        {getButtonLabel()}
      </Button>
      {isPickerOpen && (
        <div style={{ display: "none" }}>
          <DateRangePicker
            startDate={tempRange.startDate}
            startDateId="start_date_id"
            endDate={tempRange.endDate}
            endDateId="end_date_id"
            onDatesChange={({ startDate, endDate }) =>
              updateTempRange(startDate, endDate)
            }
            focusedInput={focusedInput}
            onClose={isMobile ? () => setIsPickerOpen(false) : undefined}
            withFullScreenPortal={isMobile}
            onFocusChange={handleFocusChange}
            small
            daySize={36}
            displayFormat="MMM D"
            hideKeyboardShortcutsPanel
            showClearDates
            readOnly
            numberOfMonths={isMobile ? 1 : 2}
            withPortal
            disableScroll
            renderDayContents={renderDayContents}
            renderCalendarInfo={() => (
              <CalendarInfo
                loading={loading}
                tempRange={tempRange}
                setTempRange={setTempRange}
                handleCancel={handleCancel}
                handleApply={handleApply}
              />
            )}
            calendarInfoPosition="bottom"
            navPrev={navIcon(true)}
            navNext={navIcon(false)}
            isOutsideRange={isOutsideRange}
            keepOpenOnDateSelect
          />
        </div>
      )}
    </>
  );
}
