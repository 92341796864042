import { Add } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useIsMobile } from "hooks/useIsMobile";
import noop from "lodash/noop";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";
import { Button, Icon, IconChip } from "shared-components";
import { currencyFormatter } from "../../../../../utils/formatters";
import { MediaPlanContext, useProfiles } from "../../context";
import { prepareMediaPlan } from "../../utils";
import SponsorshipErrorLabel from "../SponsorshipErrorLabel";

type NewsLetterInfoProps = {
  id: number;
  title: string;
  originalPrice: number | null;
  price: number;
  estimatedSponsorshipClicks: string;
  sponsorshipCPC: string;
  isVerified: boolean;
  nextAvailableDate: string | null;
};

// TODO: 1. check border color
//       2. What is verified badge for?
export default function NewsLetterInfo({
  id,
  title,
  originalPrice,
  price,
  estimatedSponsorshipClicks,
  sponsorshipCPC,
  isVerified,
  nextAvailableDate,
}: NewsLetterInfoProps) {
  const mediaplanContext = React.useContext(MediaPlanContext);

  if (!mediaplanContext) {
    throw new Error("useProfiles must be used within a ProfilesProvider");
  }

  const { setMediaPlan } = mediaplanContext;
  const [loading, setLoading] = React.useState(false);

  const profiles = useProfiles();
  const { mediaPlanId } = useParams();

  const isMobile = useIsMobile();

  const isInMediaPlan = !!profiles.find((profile) =>
    profile.sponsorshipOptions.find(
      (sponsorshipOption) => sponsorshipOption.id === id
    )
  );

  const toggleSponsorshipOption = async () => {
    const method = isInMediaPlan ? "delete" : "patch";
    const url = `/api/v1/advertiser/media_plans/${mediaPlanId}/${
      isInMediaPlan ? "destroy_mpso" : "add_mpso"
    }`;
    setLoading(true);
    try {
      const response = await axios({
        method,
        url,
        params: { so_id: id },
      });
      setMediaPlan(prepareMediaPlan(response.data));
    } catch (error) {
      noop();
    } finally {
      setLoading(false);
    }
  };

  const priceBox = (
    <Grid
      item
      xs={6}
      display="flex"
      gap={1}
      justifyContent={{ xs: "center", md: "flex-end" }}
    >
      {!!originalPrice && (
        <Box
          sx={{
            color: "text.secondary",
            fontSize: "14px",
            textDecoration: "line-through",
          }}
        >
          {currencyFormatter(originalPrice, 0)}
        </Box>
      )}
      <Box
        sx={{
          color: !!originalPrice ? "success.main" : "text.primary",
          fontSize: "18px",
        }}
      >
        {currencyFormatter(price, 0)}
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        padding: "20px",
        borderRadius: "8px",
        border: "1px solid #E0E0E0",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: "16px",
          gap: 3,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          flexGrow={isMobile ? 1 : 0}
          justifyContent={isMobile ? "space-between" : "flex-start"}
          sx={{ alignItems: "flex-start" }}
        >
          <Typography variant="subtitle1" fontWeight="bold">
            {title}
          </Typography>
          {isVerified && <Icon name="variant6" fill="#0E63F4" />}
        </Box>

        {!isMobile && priceBox}
      </Box>
      {nextAvailableDate && (
        <SponsorshipErrorLabel
          error={`Next available spot on ${moment(nextAvailableDate).format(
            "Do MMM YYYY"
          )}`}
        />
      )}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={9}>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <IconChip
              fullWidth={isMobile}
              icon={<Icon name="clicks" />}
              labelClassName="chip-label"
              label={estimatedSponsorshipClicks}
            />
            <IconChip
              fullWidth={isMobile}
              icon={<Icon name="cpc" />}
              labelClassName="chip-label"
              label={sponsorshipCPC}
            />
          </Box>
        </Grid>
        {isMobile && priceBox}
        <Grid item xs={6} md={3}>
          {isInMediaPlan ? (
            <Button
              disabled={loading}
              fullWidth
              size="medium"
              onClick={toggleSponsorshipOption}
              startIcon={
                loading ? <CircularProgress size={20} /> : <CloseIcon />
              }
            >
              Remove
            </Button>
          ) : (
            <Button
              disabled={loading}
              fullWidth
              variant="outlined"
              size="medium"
              onClick={toggleSponsorshipOption}
              startIcon={loading ? <CircularProgress size={20} /> : <Add />}
            >
              Add
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
