import { Box, Divider, Stack, Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { Button, Icon, theme } from "shared-components";
import { DateRange } from "../../types";

interface CalendarInfoProps {
  loading: boolean;
  tempRange: DateRange;
  setTempRange: React.Dispatch<React.SetStateAction<DateRange>>;
  handleCancel: () => void;
  handleApply: () => void;
}
export const CalendarInfo = ({
  loading,
  tempRange,
  setTempRange,
  handleCancel,
  handleApply,
}: CalendarInfoProps) => {
  const { startDate, endDate } = tempRange;
  const isMobile = useIsMobile();
  const rangeLabel =
    startDate && endDate
      ? `${startDate.format("MMM Do, YYYY")} - ${endDate.format(
          "MMM Do, YYYY"
        )}`
      : "No dates selected";

  return (
    <Stack px={2} pt={2}>
      <Button
        disabled={!startDate || !endDate}
        onClick={() => setTempRange({ startDate: null, endDate: null })}
        sx={{ mr: "auto", mb: 2 }}
        startIcon={
          <Icon
            name="inkEraser"
            fill={
              !startDate || !endDate
                ? theme.palette.text.disabled
                : theme.palette.primary.main
            }
          />
        }
      >
        Clear Selected Dates
      </Button>
      <Divider />
      <Stack
        py={2}
        gap={1}
        direction={isMobile ? "column" : "row"}
        alignItems="center"
      >
        <Stack spacing={1}>
          <Typography variant="body2" sx={{ ml: 2 }}>
            Selected period: {rangeLabel}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              sx={{
                width: 8,
                height: 8,
                bgcolor: "success.main",
                borderRadius: "50%",
              }}
            />
            <Typography variant="body2">
              Dates with discounted placements
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          width={isMobile ? "100%" : "auto"}
          ml={isMobile ? 0 : "auto"}
        >
          <Button variant="outlined" fullWidth onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={!startDate || !endDate || loading}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
