import React, { useState, useEffect } from "react";
import NewsLetterInfo from "./NewsLetterInfo";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export type NewsLetterData = {
  id: number;
  name: string;
  type: "dedicated_email" | "sponsored_post" | "sponsored_email";
  click_rate: string;
  cost_per_click: string;
  price: number;
  discount_price: number | null;
  verified_stats: boolean;
  next_available_date: string | null;
};

type NewsLetterInfoListProps = {
  data: NewsLetterData[] | null;
  setData: (data: NewsLetterData[]) => void;
  onExpandClick: () => void;
  expanded: boolean;
};

export default function NewsLetterInfoList({
  data,
  setData,
  onExpandClick,
  expanded,
}: NewsLetterInfoListProps) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // TODO: catch data receive error
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (expanded && !data) {
      setIsLoading(true);
      onExpandClick();
    }
  }, [expanded, data, onExpandClick]);

  return (
    <Box sx={{ paddingTop: "16px" }}>
      <Accordion
        expanded={expanded}
        disabled={isLoading}
        sx={{
          boxShadow: "none",
          "&.Mui-disabled": {
            backgroundColor: "white",
            opacity: 1,
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            isLoading ? <CircularProgress size={24} /> : <ExpandMoreIcon />
          }
          sx={{
            "& .MuiAccordionSummary-content": {
              display: "none",
            },
            "&.Mui-disabled": {
              opacity: 1,
            },
          }}
        />
        <AccordionDetails sx={{ p: 0 }}>
          {!!data
            ? data.map((item) => {
                // TODO: ask about title (icon for type?)
                return (
                  <Box sx={{ mb: "16px" }} key={`${item.type} ${item.name}`}>
                    <NewsLetterInfo
                      id={item.id}
                      title={item.name}
                      originalPrice={item.discount_price ? item.price : null}
                      price={item.discount_price || item.price}
                      estimatedSponsorshipClicks={item.click_rate}
                      sponsorshipCPC={item.cost_per_click}
                      isVerified={item.verified_stats}
                      nextAvailableDate={item.next_available_date}
                    />
                  </Box>
                );
              })
            : null}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
