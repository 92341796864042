import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { Moment } from "moment";
import { useContext, useState } from "react";
import { CommonDrawer, ConfirmationMessageDialog } from "shared-components";
import FormWrapper from "../../../../components/FormWrapper";
import { TextField } from "../../../../components/MUIWrappers";
import { LayoutContext } from "../../../../context";
import PriceInfoData from "./PriceInfoData";
import axios from "axios";
import { useParams } from "react-router-dom";
import noop from "lodash/noop";
import * as Yup from "yup";

const formName = "book-media-plan";

export type ConfirmationProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  discountPrice: number;
  regularPrice: number;
  totalPrice: number;
  discountPercent?: number;
  expireInDays?: number;
  startDate: Moment | null;
  endDate: Moment | null;
  sponsorshipsQty: number;
  mediaPlanName: string;
};

function periodString(startDate: Moment | null, endDate: Moment | null) {
  return `${startDate?.format("MMM Do, YYYY")} - ${endDate?.format(
    "MMM Do, YYYY"
  )}`;
}

function sponsorshipsQtyString(sponsorshipsQty: number) {
  return sponsorshipsQty === 1
    ? `${sponsorshipsQty} Sponsorship`
    : `${sponsorshipsQty} Sponsorships`;
}

const validationSchema = Yup.object().shape({
  messageToPublishers: Yup.string().required("This field is required."),

  confirmed: Yup.boolean().oneOf(
    [true],
    "You must confirm that you understand the cancellation policy"
  ),
});

type ConfirmationFormProps = {
  onSubmit: () => void;
};

function ConfirmationForm({ onSubmit }: ConfirmationFormProps) {
  const { setIsDataLoading } = useContext(LayoutContext);
  const { mediaPlanId } = useParams();

  const initialMessageToPublishers =
    localStorage.getItem("lastMessageToPublishers") || "";

  return (
    <Formik
      initialValues={{
        messageToPublishers: initialMessageToPublishers,
        confirmed: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsDataLoading(true);
        localStorage.setItem(
          "lastMessageToPublishers",
          values.messageToPublishers
        );

        axios
          .post(
            `/api/v1/advertiser/media_plans/${mediaPlanId}/book_sponsorships`,
            {
              message: values.messageToPublishers,
            }
          )
          .then(() => onSubmit())
          .catch(noop)
          .finally(() => setIsDataLoading(false));
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <FormWrapper onSubmit={handleSubmit} name={formName} id={formName}>
          <Divider sx={{ mb: 3 }} />
          <Typography sx={{ mb: 3 }}>
            Please share a few details about your brand and what you are
            planning to promote.
          </Typography>
          <Box sx={{ mb: 4 }}>
            <TextField
              fullWidth
              id="messageToPublishers"
              name="messageToPublishers"
              label="Message to publishers"
              placeholder="Write your message..."
              multiline
              rows={8}
              value={values.messageToPublishers}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.messageToPublishers &&
                Boolean(errors.messageToPublishers)
              }
              helperText={
                touched.messageToPublishers && errors.messageToPublishers
              }
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.confirmed}
                onChange={handleChange}
                color="primary"
                name="confirmed"
              />
            }
            sx={{
              color: "text.secondary",
              fontSize: "16px",
              py: 1,
              pr: 1,
              borderRadius: "8px",
              mx: 0,
              bgcolor:
                errors.confirmed && touched.confirmed
                  ? "#FCEFF0"
                  : "transparent",
            }}
            label="You understand this is a request for a sponsorship. If accepted by the publisher, you may not be able to cancel this request without paying a cancellation fee."
          />
        </FormWrapper>
      )}
    </Formik>
  );
}

function ConfirmationInfoRow({
  caption,
  value,
}: {
  caption: string;
  value: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        mb: "20px",
        fontSize: "16px",
        alignItems: "center",
      }}
    >
      <Box sx={{ fontWeight: 700 }}>{caption}:</Box>
      <Box>{value}</Box>
    </Box>
  );
}

export default function Confirmation({
  open,
  onClose,
  onConfirm,
  title,
  regularPrice,
  discountPercent,
  discountPrice,
  expireInDays,
  totalPrice,
  startDate,
  endDate,
  sponsorshipsQty,
  mediaPlanName,
}: ConfirmationProps) {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setIsSuccessModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    window.location.href = "/advertiser/media_plans";
  };

  const handleNewMediaPlan = () => {
    window.location.href = "/advertiser/media_plans/new";
  };

  return (
    <>
      <CommonDrawer
        open={open}
        onClose={onClose}
        title={title}
        actions={[
          {
            caption: "Cancel",
            onClick: onClose,
            type: "button",
          },
          {
            caption: "Confirm",
            type: "submit",
            form: formName,
          },
        ]}
        dividers
      >
        <Box p={2}>
          <ConfirmationInfoRow
            caption="Period"
            value={periodString(startDate, endDate)}
          />
          <ConfirmationInfoRow
            caption="Total"
            value={sponsorshipsQtyString(sponsorshipsQty)}
          />
          <Divider sx={{ mb: 3 }} />
          <PriceInfoData
            discountPrice={discountPrice}
            regularPrice={regularPrice}
            discountPercent={discountPercent}
            expireInDays={expireInDays}
            totalPrice={totalPrice}
          />
          <ConfirmationForm onSubmit={handleConfirm} />
        </Box>
      </CommonDrawer>

      {isSuccessModalOpen && (
        <ConfirmationMessageDialog
          onClose={() => setIsSuccessModalOpen(false)}
          open={isSuccessModalOpen}
          variant="success"
          messageTitle="Your Media Plan is Booked"
          messageDescription={
            <>
              <Typography variant="body1">
                All sponsorships in the {mediaPlanName} media plan are booked.
                Track their progress through your dashboard.
              </Typography>
              <Typography variant="body1">
                <br />
                We’ll inform you of any updates. Please act quickly when
                required.
              </Typography>
            </>
          }
          actions={[
            {
              caption: "My Dashboard",
              onClick: handleCloseSuccessModal,
            },
            {
              caption: "New Media Plan",
              onClick: handleNewMediaPlan,
              type: "submit",
            },
          ]}
        />
      )}
    </>
  );
}
