import React, { useState } from "react";
import { Paper } from "../../../../components/MUIWrappers";
import { Box, Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DateRange } from "../types";
import PriceInfoData, { PriceInfoDataProps } from "./PriceInfoData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Add } from "@mui/icons-material";
import { Button } from "shared-components";

// TODO:
//  1. Check colors with Daniel. Could we use colors by names in Figma?

export type PriceInfoProps = PriceInfoDataProps & {
  dateRange?: DateRange;
  onAddSponsorshipClick: () => void;
  onBookClick: () => void;
};

// TODO: Common component ?
function InfoMessage({
  type,
  message,
  sx,
}: {
  type: "warning" | "info";
  message: string;
  sx?: SxProps<Theme>;
}) {
  const color = type === "warning" ? "error.dark" : "primary.dark";

  return (
    <Box sx={{ ...sx, color, fontSize: "12px" }}>
      <InfoOutlinedIcon sx={{ fontSize: "18px", verticalAlign: -4 }}>
        info
      </InfoOutlinedIcon>
      &nbsp; {message}
    </Box>
  );
}

const ShowDetailsButton = ({
  showDetails,
  setShowDetails,
}: {
  showDetails: boolean;
  setShowDetails: (value: boolean) => void;
}) => (
  <Button
    onClick={() => setShowDetails(!showDetails)}
    sx={{
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      top: -24,
      height: 48,
      width: 48,
      minWidth: 48,
      borderRadius: "50%",
      bgcolor: "common.white",
      boxShadow: "0px -2px 8px 0px #EEE7F7",
      "&:hover": {
        bgcolor: "common.white",
      },
    }}
  >
    <ExpandMoreIcon
      sx={{
        transition: "transform 0.3s",
        transform: showDetails ? "rotate(0deg)" : "rotate(180deg)",
      }}
    />
  </Button>
);

export default function PriceInfo({
  booked,
  regularPrice,
  totalPrice,
  discountPrice,
  discountPercent,
  expireInDays,
  dateRange,
  onAddSponsorshipClick,
  onBookClick,
}: PriceInfoProps) {
  const isMobile = useIsMobile();
  const [showDetails, setShowDetails] = useState(() => !isMobile);

  return (
    <Paper
      sx={{
        p: "24px 12px 16px 12px",
        "&.MuiPaper-root": { minHeight: "auto" },
      }}
    >
      {isMobile && !!totalPrice && totalPrice !== regularPrice && (
        <ShowDetailsButton
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      )}
      <PriceInfoData
        discountPrice={discountPrice}
        regularPrice={regularPrice}
        totalPrice={totalPrice}
        discountPercent={discountPercent}
        expireInDays={expireInDays}
        showDetails={showDetails}
      />
      <Stack direction={isMobile ? "row" : "column"} spacing={1}>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={onAddSponsorshipClick}
          sx={{ whiteSpace: "nowrap" }}
          startIcon={<Add />}
          disabled={booked}
        >
          Add Sponsorship
        </Button>
        <Button
          variant="contained"
          fullWidth
          size="large"
          disabled={!totalPrice || booked}
          onClick={onBookClick}
        >
          {isMobile ? "Book All" : "Book All Sponsorships"}
        </Button>
      </Stack>
      {!dateRange && (
        <>
          <InfoMessage
            type="warning"
            message="Choose a date range to continue"
            sx={{ mb: "4px" }}
          />
          <InfoMessage type="info" message="Date range dictates discounts" />
        </>
      )}
    </Paper>
  );
}
