import { Alert, Grid } from "@mui/material";
import { Button, Icon, theme } from "shared-components";

function MediaPlanWarning() {
  return (
    <Alert
      severity="success"
      icon={<Icon name="checkCircle" fill={theme.palette.success.main} />}
      sx={{
        mb: 2,
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={10}>
          <b>Sponsorships Booked</b> The sponsorships in this plan are now being
          requested from the publishers. You can keep track of the progress in
          your Sponsorships panel.
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={() => (window.location.href = "/advertiser/campaigns")}
            size="small"
          >
            View Sponsorships
          </Button>
        </Grid>
      </Grid>
    </Alert>
  );
}
export default MediaPlanWarning;
