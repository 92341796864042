import React, { useState, useCallback } from "react";
import HitComponent, {
  Hit as CustomHit,
} from "../../../../marketplace/components/Hit";
import NewsLetterInfoList, { NewsLetterData } from "./NewsLetterInfoList";
import { useParams } from "react-router-dom";
import axios from "axios";
import noop from "lodash/noop";

// TODO: real favorites[]
export default function ProfileCardSponsorship({ hit }: { hit: CustomHit }) {
  const { mediaPlanId } = useParams();
  const [newsLetterInfoData, setNewsLetterInfoData] = useState<
    NewsLetterData[] | null
  >(null);
  const [expanded, setExpanded] = useState(false);
  const receiveData = useCallback(() => {
    if (newsLetterInfoData) {
      return;
    }
    axios
      .get(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/sponsorship_options?slug=${hit.slug}`
      )
      .then((r) => {
        setNewsLetterInfoData(r.data);
      })
      .catch(noop);
  }, [newsLetterInfoData, hit.slug, mediaPlanId]);

  const handleCardClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div onClick={handleCardClick}>
      <HitComponent
        key={hit.objectID}
        hit={hit}
        href={`/sites/${hit.slug}?mp_id=${mediaPlanId}`}
        target="_blank"
        wrapEntireContent={false}
      >
        <NewsLetterInfoList
          data={newsLetterInfoData}
          setData={setNewsLetterInfoData}
          onExpandClick={receiveData}
          expanded={expanded}
        />
      </HitComponent>
    </div>
  );
}
