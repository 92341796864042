import { Add, Close } from "@mui/icons-material";
import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { Paper } from "components/MUIWrappers";
import { noop } from "lodash";
import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "shared-components";
import { MediaPlanContext } from "../../context";
import { Profile } from "../../types";
import { prepareMediaPlan } from "../../utils";
import RemoveProfileModal from "./RemoveProfileModal";
import SponsorshipItem from "./SponsorshipItem";

interface SponsorshipListProps {
  profiles: Profile[];
  onAddSponsorshipClick: () => void;
}

const headerRow = (
  <Grid
    container
    sx={{
      borderBottom: "1px solid #DFE3EB",
      borderRadius: "8px",
      fontWeight: "bold",
      p: "8px 20px",
      mb: 3,
      boxShadow: "0px 2px 8px 0px #EEE7F766",
    }}
  >
    <Grid item xs={0.5} />
    <Grid item xs={5} py={{ xs: "6px", md: "12px" }}>
      <Typography noWrap fontWeight="bold" fontSize="16px">
        Newsletter and Placement
      </Typography>
    </Grid>
    <Grid item xs={3} py={{ xs: "6px", md: "12px" }} textAlign="center">
      <Typography noWrap fontWeight="bold" fontSize="16px">
        Quantity
      </Typography>
    </Grid>
    <Grid item xs={3} py={{ xs: "6px", md: "12px" }} textAlign="right">
      <Typography noWrap fontWeight="bold" fontSize="16px">
        Price
      </Typography>
    </Grid>
    <Grid item xs={0.5} />
  </Grid>
);

const SponsorshipsList: FC<SponsorshipListProps> = ({
  profiles,
  onAddSponsorshipClick,
}) => {
  const mediaPlanContext = useContext(MediaPlanContext);
  if (!mediaPlanContext) {
    throw new Error("useProfiles must be used within a ProfilesProvider");
  }
  const { setMediaPlan, isBooked } = mediaPlanContext;
  const { mediaPlanId } = useParams();
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [modalType, setModalType] = useState<"confirm" | "success" | null>(
    null
  );

  const openRemoveModal = (profile: Profile) => {
    setSelectedProfile(profile);
    setModalType("confirm");
  };

  const handleRemove = async (profile: Profile) => {
    try {
      const response = await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/`,
        {
          params: { site_id: profile.id },
        }
      );

      setMediaPlan(prepareMediaPlan(response.data));
      setModalType("success");
    } catch (error) {
      noop();
    }
  };

  return (
    <Paper sx={{ overflow: "auto" }}>
      <Box sx={{ minWidth: "600px" }}>
        {headerRow}
        {profiles.map((profile) => (
          <Box key={profile.name} sx={{ my: 3 }} px={2}>
            <Grid
              container
              alignItems="center"
              sx={{
                position: "relative",
                color: isBooked ? "text.primary" : "primary.main",
                "&:hover .profile-remove": { opacity: 1 },
              }}
            >
              <Grid item xs={12} md={0.5} textAlign="right">
                <Avatar
                  src={profile.logo}
                  variant="square"
                  sx={{ width: 28, height: 28 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  noWrap
                  fontWeight="bold"
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (isBooked) return;
                    e.preventDefault();
                    window.open(`/sites/${profile.slug}`, "_blank");
                  }}
                >
                  {profile.name}
                </Typography>
              </Grid>
              <Grid item xs={1} md={0.5} textAlign="right">
                {!isBooked && (
                  <Close
                    className="profile-remove"
                    sx={{
                      opacity: { xs: 1, md: 0 },
                      transition: "opacity 0.3s",
                      cursor: "pointer",
                      color: "text.primary",
                      "&:hover": { color: "primary.main" },
                    }}
                    fontSize="small"
                    onClick={() => openRemoveModal(profile)}
                  />
                )}
              </Grid>
            </Grid>
            {profile.sponsorshipOptions.map((sponsorship) => (
              <Box key={sponsorship.name} sx={{ my: 2 }}>
                <SponsorshipItem
                  sponsorship={sponsorship}
                  profileSlug={profile.slug}
                />
              </Box>
            ))}
            <Divider />
          </Box>
        ))}
      </Box>

      {selectedProfile && (
        <RemoveProfileModal
          profile={selectedProfile}
          modalType={modalType}
          setModalType={setModalType}
          handleRemove={handleRemove}
        />
      )}

      {profiles.length === 0 && (
        <Stack justifyContent="center" alignItems="center" py="81px">
          <Typography
            variant="caption"
            color="text.secondary"
            textAlign="center"
          >
            Your media plan is empty.
            <br />
            Start building your media plan by adding a sponsorship.
          </Typography>
          <Button startIcon={<Add />} onClick={onAddSponsorshipClick}>
            Add Sponsorship
          </Button>
        </Stack>
      )}
    </Paper>
  );
};

export default SponsorshipsList;
