import { Close } from "@mui/icons-material";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { TextField } from "components/MUIWrappers";
import { NewInvite } from "../../types";

type MediaPlanShareItemProps = {
  readOnly: false;
  newInvite: NewInvite;
  onChange: (field: keyof NewInvite, value: string) => void;
  onRemove: () => void;
  canRemove: boolean;
  errors?: Partial<Record<keyof NewInvite, string | undefined>>;
  touched?: Partial<Record<keyof NewInvite, boolean>>;
};

export function MediaPlanShareItem(props: MediaPlanShareItemProps) {
  const {
    newInvite,
    onChange,
    onRemove,
    canRemove,
    errors = {},
    touched = {},
  } = props;
  const { firstName, lastName, email } = newInvite;
  const fieldProps = (
    placeholder: string,
    field: keyof NewInvite,
    value: string,
    label: string
  ) => (
    <>
      <Typography ml="4px" fontSize="14px" variant="caption">
        {label}
      </Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(field, e.target.value)}
        error={Boolean(touched[field] && errors[field])}
        helperText={touched[field] && errors[field]}
      />
    </>
  );

  return (
    <Stack direction="row" bgcolor="#F8FAFF" px={2} py={2} sx={{ mb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {fieldProps("E.g. John", "firstName", firstName, "First Name")}
        </Grid>
        <Grid item xs={12} sm={6}>
          {fieldProps("E.g. Doe", "lastName", lastName, "Last Name")}
        </Grid>
        <Grid item xs={12}>
          {fieldProps("E.g. john@example.com", "email", email, "Email")}
        </Grid>
      </Grid>
      {canRemove && (
        <IconButton
          onClick={onRemove}
          sx={{ ml: 1, alignSelf: "start", mt: 4 }}
        >
          <Close fontSize="small" sx={{ color: "text.disabled" }} />
        </IconButton>
      )}
    </Stack>
  );
}
