import ClearIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import algoliasearch from "algoliasearch/lite";
import {
  Configure,
  InstantSearch,
  useHits,
  useSearchBox,
} from "react-instantsearch";
import { Hit } from "react-instantsearch-core";
import { CommonDrawer } from "shared-components";
import { Paper, TextField } from "../../../../components/MUIWrappers";
import { Hit as CustomHit } from "../../../marketplace/components/Hit";

import { Close } from "@mui/icons-material";
import ProfileCardSponsorship from "./ProfileCardSponsorship";
import "./profilesearch.scss";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient =
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID &&
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
    ? algoliasearch(
        process.env.REACT_APP_ALGOLIASEARCH_APP_ID,
        process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
      )
    : null;

function SearchResults() {
  const { items } = useHits() as { items: Hit<CustomHit>[] };

  return (
    <Box sx={{ p: "24px" }} className="publishers">
      <Box className="list">
        {items.map((hit: any) => (
          <ProfileCardSponsorship key={hit.objectID} hit={hit} />
        ))}
      </Box>
    </Box>
  );
}

function CustomSearchBox() {
  const { query, refine } = useSearchBox();

  return (
    <TextField
      placeholder="Search..."
      variant="outlined"
      value={query}
      fullWidth
      onChange={(event) => refine(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            className="algolia-search__autocomplete-icon"
            sx={{ mr: "-8px" }}
          >
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: query ? (
          <InputAdornment position="end">
            <IconButton onClick={() => refine("")}>
              <ClearIcon style={{ color: "#A0A3B1" }} />
            </IconButton>
          </InputAdornment>
        ) : null,
        sx: {
          borderRadius: "8px",
          height: "48px",
        },
      }}
      className="algolia-search__autocomplete-input"
    />
  );
}

export default function ProfileSearch({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return searchClient ? (
    <CommonDrawer open={open} onClose={onClose}>
      <Box className="profile-search-wrapper" sx={{ maxWidth: "100%" }}>
        <InstantSearch indexName={INDEX_NAME} searchClient={searchClient}>
          <Configure
            facets={[
              "audience_primary_age",
              "category_names",
              "favorites",
              "formats",
              "has_discounts?",
              "in_demand?",
              "is_verified?",
              "list_size",
              "picked",
              "sponsored_email_dates",
            ]}
          />
          <Box
            sx={{
              maxHeight: "88px",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 1,
              backgroundColor: "background.paper",
            }}
          >
            <Paper sx={{ p: "20px 24px" }}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ gap: "12px", padding: "8px" }}
              >
                <CustomSearchBox />
                <IconButton onClick={onClose} size="small">
                  <Close sx={{ color: "text.secondary", fontSize: "16px" }} />
                </IconButton>
              </Box>
            </Paper>
          </Box>
          <SearchResults />
        </InstantSearch>
      </Box>
    </CommonDrawer>
  ) : null;
}
