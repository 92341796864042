import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import axios from "axios";
import useComponentDidMount from "hooks/useComponentDidMount";
import noop from "lodash/noop";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useIsMobile } from "hooks/useIsMobile";
import MediaPlanInfo from "./components/MediaPlanInfo";
import MediaPlanAlert from "./components/MediaPlanInfo/MediaPlanAlert";
import PriceInfo from "./components/PriceInfo";
import ProfileSearch from "./components/ProfileSearch";
import Recommendations from "./components/Recommendations";
import SponsorshipsList from "./components/SponsorshipsList";
import { DateRange, MediaPlanData } from "./types";
import { prepareMediaPlan } from "./utils";
import Confirmation from "./components/Confirmation";
import { MediaPlanContext } from "./context";
import { UserContext } from "../../../context";
import { useRefreshOnTabFocus } from "../../../hooks/useRefreshOnTabFocus";
import { segmentsTrack } from "../../../utils/analytics";

function countSponsorshipsQty(profiles: MediaPlanData["profiles"]) {
  return profiles.reduce(
    (acc, profile) =>
      acc +
      profile.sponsorshipOptions.reduce((acc, sponsorship) => {
        return acc + sponsorship.quantity;
      }, 0),
    0
  );
}
export default function MediaPlan() {
  const { mediaPlanId } = useParams();
  const {
    setBreadcrumbLabel,
    userData: { companyId },
  } = useContext(UserContext);
  const [mediaPlan, setMediaPlan] = useState<MediaPlanData | null>(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });
  const isMobile = useIsMobile();
  useEffect(() => {
    if (mediaPlan) {
      setDateRange({
        startDate: mediaPlan.startDate ? moment(mediaPlan.startDate) : null,
        endDate: mediaPlan.endDate ? moment(mediaPlan.endDate) : null,
      });
    }
  }, [mediaPlan]);

  const sponsorshipsQty = mediaPlan
    ? countSponsorshipsQty(mediaPlan.profiles)
    : 0;

  const fetchData = useCallback(() => {
    axios
      .get(`/api/v1/advertiser/media_plans/${mediaPlanId}`)
      .then((res) => {
        const mediaPlanData = res.data;
        setBreadcrumbLabel(mediaPlanData.name);
        setMediaPlan(prepareMediaPlan(mediaPlanData));
      })
      .catch(noop);
  }, [mediaPlanId, setBreadcrumbLabel]);

  useComponentDidMount(fetchData);
  useRefreshOnTabFocus(fetchData);

  const objectIDs =
    mediaPlan?.profiles.map((profile) => String(profile.id)) || [];

  return mediaPlan ? (
    <MediaPlanContext.Provider
      value={{ ...mediaPlan, setMediaPlan, dateRange }}
    >
      <Confirmation
        mediaPlanName={mediaPlan.name}
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={() => {
          segmentsTrack("media_plan_booked", {
            media_plan_name: mediaPlan.name,
            media_plan_booking_period_from: mediaPlan.startDate,
            media_plan_booking_period_until: mediaPlan.endDate,
            media_plan_uuid: mediaPlanId,
            newsletter_count: mediaPlan.profiles.length,
            sponsorship_count: sponsorshipsQty,
            discount_amount: mediaPlan.discountPrice,
            discount_percentage: mediaPlan.discountPercent,
            media_plan_budget_before_discount: mediaPlan.regularPrice,
            media_plan_budget_after_discount: mediaPlan.totalPrice,
          });

          setConfirmationDialogOpen(false);
        }}
        title={mediaPlan.name}
        regularPrice={mediaPlan.regularPrice}
        totalPrice={mediaPlan.totalPrice}
        discountPrice={mediaPlan.discountPrice}
        discountPercent={mediaPlan.discountPercent}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        sponsorshipsQty={sponsorshipsQty}
      />
      <ProfileSearch
        open={sidePanelOpen}
        onClose={() => setSidePanelOpen(false)}
      />
      <Box mb={{ xs: 0, md: 2 }}>
        <MediaPlanInfo />
      </Box>

      {mediaPlan.isBooked && <MediaPlanAlert />}

      <Grid
        container
        spacing={{ sx: 0, md: 2 }}
        mb={{ sx: 0, md: 6 }}
        position="relative"
      >
        <Grid
          item
          xs={12}
          md={9}
          minHeight={{ xs: "66svh", md: "auto" }}
          overflow="hidden"
        >
          <SponsorshipsList
            profiles={mediaPlan.profiles}
            onAddSponsorshipClick={() => setSidePanelOpen(true)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            position: { xs: "sticky" },
            bottom: { xs: 0, md: "auto" },
            top: { xs: "auto", md: 103 },
            maxHeight: 296,
            boxShadow: { xs: "0px -2px 8px 0px #EEE7F7", md: "none" },
          }}
        >
          <PriceInfo
            booked={mediaPlan.isBooked}
            regularPrice={mediaPlan.regularPrice}
            totalPrice={mediaPlan.totalPrice}
            discountPrice={mediaPlan.discountPrice}
            discountPercent={mediaPlan.discountPercent}
            dateRange={dateRange}
            onAddSponsorshipClick={() => setSidePanelOpen(true)}
            onBookClick={() => {
              segmentsTrack("media_plans_book_button_click", {
                media_plan_name: mediaPlan.name,
                media_plan_uuid: mediaPlanId,
              });
              setConfirmationDialogOpen(true);
            }}
          />
        </Grid>
      </Grid>
      {!isMobile && !mediaPlan.isBooked && !!companyId && (
        <Recommendations companyId={companyId} objectIDs={objectIDs} />
      )}
    </MediaPlanContext.Provider>
  ) : null;
}
