import { createContext, useContext } from "react";
import { DateRange, MediaPlanData } from "./types";

type MediaPlanContextProps = MediaPlanData & {
  setMediaPlan: (mediaPlan: MediaPlanData) => void;
  dateRange: DateRange;
};

export const MediaPlanContext = createContext<MediaPlanContextProps | null>(
  null
);

export const useProfiles = () => {
  const context = useContext(MediaPlanContext);
  if (!context) {
    throw new Error("useProfiles must be used within a ProfilesProvider");
  }

  return context.profiles;
};
