import { Box } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import React, { useEffect, useRef, useState } from "react";
import {
  Configure,
  InstantSearch,
  useHits,
  useInstantSearch,
  useRelatedProducts,
} from "react-instantsearch";
import { Hit } from "react-instantsearch-core";
import { Hit as CustomHit } from "../../../marketplace/components/Hit";
import RecommendationsSlider from "./HitsSlider";
import "./recommendations.scss";
import useComponentDidMount from "../../../../hooks/useComponentDidMount";

const INDEX_NAME = `Site_${process.env.REACT_APP_APP_ENV}`;

const searchClient =
  process.env.REACT_APP_ALGOLIASEARCH_APP_ID &&
  process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
    ? algoliasearch(
        process.env.REACT_APP_ALGOLIASEARCH_APP_ID,
        process.env.REACT_APP_ALGOLIASEARCH_READ_KEY_PUBLIC
      )
    : null;

const Recommendations = React.memo(function Recommendations({
  companyId,
  objectIDs,
}: {
  companyId: number;
  objectIDs: string[];
}) {
  const [filters, setFilters] = useState(`recommendations:${companyId}`);

  if (!searchClient) {
    return null;
  }

  return (
    <InstantSearch indexName={INDEX_NAME} searchClient={searchClient}>
      <Configure
        filters={filters}
        facets={[
          "audience_primary_age",
          "category_names",
          "favorites",
          "formats",
          "has_discounts?",
          "in_demand?",
          "is_verified?",
          "list_size",
          "picked",
          "sponsored_email_dates",
        ]}
      />
      <Box className="recommendations-wrapper">
        <Box className="publishers">
          {objectIDs.length ? (
            <RelatedProductsContent objectIDs={objectIDs} />
          ) : (
            <RecommendationsContent setFilters={setFilters} />
          )}
        </Box>
      </Box>
    </InstantSearch>
  );
});

function RelatedProductsContent({ objectIDs }: { objectIDs: string[] }) {
  const { items } = useRelatedProducts({
    objectIDs,
  }) as { items: Hit<CustomHit>[] };
  const { status } = useInstantSearch();

  return (
    <RecommendationsSlider
      items={items.slice(0, 10)}
      isLoading={status === "loading"}
      title="Recommendations"
    />
  );
}

function RecommendationsContent({
  setFilters,
}: {
  setFilters: (filters: string) => void;
}) {
  const { items } = useHits() as { items: Hit<CustomHit>[] };
  const { status } = useInstantSearch();
  const itemsRef = useRef(items);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const resetFilters = () => {
    if (itemsRef.current.length === 0) {
      setFilters("");
    }
  };

  useComponentDidMount(() => {
    // TODO: if we have a lot of users without recommendations on prod,
    //  we should check status;
    //  It requires more effort to implement it. useRef e.t.c.
    setTimeout(resetFilters, 2_000);
  });

  return (
    <RecommendationsSlider
      items={items.slice(0, 10)}
      isLoading={status === "loading"}
      title="Recommendations"
    />
  );
}

export default Recommendations;
