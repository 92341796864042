import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { FieldArray, Form, Formik, FormikHelpers } from "formik";
import { Button, CommonDrawer } from "shared-components";
import * as Yup from "yup";
import { NewInvite } from "../../types";
import { MediaPlanShareItem } from "./MediaPlanShareItem";

const newInviteSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

const invitesSchema = Yup.object({
  invites: Yup.array()
    .of(newInviteSchema)
    .min(1, "At least one invite is required"),
});

type MediaPlanShareDrawerProps = {
  open: boolean;
  onClose: () => void;
  onSendInvitations: (invites: NewInvite[]) => void;
};

interface FormValues {
  invites: NewInvite[];
}

export default function MediaPlanShareDrawer({
  open,
  onClose,
  onSendInvitations,
}: MediaPlanShareDrawerProps) {
  const initialValues: FormValues = {
    invites: [{ firstName: "", lastName: "", email: "" }],
  };

  const handleSubmit = (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    onSendInvitations(values.invites);
    helpers.resetForm();
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={invitesSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => (
        <CommonDrawer
          open={open}
          onClose={onClose}
          title="Share Media Plan"
          actions={[
            {
              caption: "Cancel",
              onClick: onClose,
              type: "button",
            },
            {
              caption: "Send Invitation",
              type: "submit",
              form: "mediaPlanShareForm",
              disabled: !isValid || values.invites.length === 0,
            },
          ]}
          dividers
        >
          <Form id="mediaPlanShareForm">
            <Box py={2}>
              <Box
                sx={{
                  mb: 2,
                  px: 2,
                }}
              >
                <Typography fontSize={14}>
                  Add your team members so they can access the media plan.
                  <br /> Invited team members will be able to view, edit, and
                  book sponsorships.
                </Typography>
              </Box>
              <FieldArray name="invites">
                {({ push, remove }) => (
                  <>
                    {values.invites.map((invite, i) => (
                      <MediaPlanShareItem
                        key={`new-${i}`}
                        readOnly={false}
                        newInvite={invite}
                        onChange={(field, value) => {
                          setFieldValue(`invites[${i}].${field}`, value);
                          setFieldTouched(
                            `invites[${i}].${field}`,
                            true,
                            false
                          );
                        }}
                        onRemove={() => remove(i)}
                        errors={(errors.invites?.[i] as any) || {}}
                        touched={(touched.invites?.[i] as any) || {}}
                        canRemove={values.invites.length > 1}
                      />
                    ))}
                    <Stack mt={2} px={2}>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          push({ firstName: "", lastName: "", email: "" })
                        }
                        fullWidth
                        sx={{ height: 48 }}
                      >
                        <Add sx={{ mr: 1 }} />
                        Add Another
                      </Button>
                    </Stack>
                  </>
                )}
              </FieldArray>
            </Box>
          </Form>
        </CommonDrawer>
      )}
    </Formik>
  );
}
