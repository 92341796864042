import { Close, InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { debounce, noop } from "lodash";
import { formatCurrencyNoDecimals } from "pages/publisher/add-site/schedule/calendar/utils";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MediaPlanContext } from "../../context";
import { SponsorshipOption } from "../../types";
import { prepareMediaPlan } from "../../utils";
import Counter from "./Counter";
import { Tooltip } from "components/MUIWrappers";
import SponsorshipErrorLabel from "../SponsorshipErrorLabel";

interface SponsorshipItemProps {
  sponsorship: SponsorshipOption;
  profileSlug: string;
}

const iconStyle = {
  opacity: { xs: 1, md: 0 },
  transition: "opacity 0.3s",
  cursor: "pointer",
  color: "text.primary",
  "&:hover": { color: "primary.main" },
};

const SponsorshipItem: FC<SponsorshipItemProps> = ({
  sponsorship,
  profileSlug,
}) => {
  const {
    quantity,
    id,
    uuid,
    name,
    price,
    regularPrice,
    description,
    availableDates,
  } = sponsorship;

  const { setMediaPlan, isBooked } = useContext(MediaPlanContext)!;
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const [loading, setLoading] = useState(false);
  const { mediaPlanId } = useParams();

  const getCounterTooltipTitle = () => {
    if (availableDates === 0) {
      return "There's no more available dates within the selected period.";
    }
    return `This placement has ${availableDates} available dates within your selected range. To include more spots, please expand the date range.`;
  };

  const updateQuantity = useMemo(
    () =>
      debounce(async (newQuantity: number) => {
        if (newQuantity === quantity) return;
        setLoading(true);
        try {
          const { data } = await axios.patch(
            `/api/v1/advertiser/media_plans/${mediaPlanId}/update_quantity`,
            null,
            { params: { so_id: id, quantity: newQuantity } }
          );
          setMediaPlan(prepareMediaPlan(data));
        } catch (error) {
          noop();
        } finally {
          setLoading(false);
        }
      }, 500),
    [mediaPlanId, id, quantity, setMediaPlan]
  );

  useEffect(() => {
    updateQuantity(localQuantity);
    return updateQuantity.cancel;
  }, [localQuantity, updateQuantity]);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const handleRemove = async () => {
    try {
      const { data } = await axios.delete(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/destroy_mpso`,
        { params: { so_id: id } }
      );
      setMediaPlan(prepareMediaPlan(data));
    } catch (error) {
      noop();
    }
  };

  const renderPrice = () => (
    <>
      {price !== regularPrice && (
        <Typography
          component="span"
          sx={{ textDecoration: "line-through" }}
          color="grey.500"
          mr={0.5}
        >
          {formatCurrencyNoDecimals(regularPrice)}
        </Typography>
      )}
      {formatCurrencyNoDecimals(availableDates === 0 ? 0 : price)}
    </>
  );

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        py: 1,
        "&:hover": { color: isBooked ? "text.primary" : "primary.main" },
        "&:hover .sponsorship-info, &:hover .sponsorship-close": { opacity: 1 },
      }}
    >
      <Grid
        item
        xs={0.5}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip
          title={description}
          disableInteractive
          arrow
          placement="right"
          enterTouchDelay={0}
          leaveTouchDelay={10000}
        >
          <InfoOutlined
            className="sponsorship-info"
            sx={iconStyle}
            fontSize="small"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={5}>
        <Typography
          noWrap
          sx={{ cursor: isBooked ? "default" : "pointer" }}
          onClick={() => {
            if (!isBooked) {
              window.open(`/sites/${profileSlug}#${uuid}`, "_blank");
            }
          }}
        >
          {name}
        </Typography>
        {(availableDates < 1 || availableDates < quantity) && (
          <SponsorshipErrorLabel
            error={`${availableDates < 1 ? "" : "Only"} ${availableDates} spot${
              availableDates !== 1 ? "s" : ""
            } available for the selected period.`}
          />
        )}
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Tooltip
          title={isBooked ? null : getCounterTooltipTitle()}
          disableInteractive
          arrow
          placement="bottom"
        >
          <div>
            <Counter
              value={availableDates < 1 ? 0 : localQuantity}
              onChange={setLocalQuantity}
              minValue={0}
              maxValue={10}
              disabled={loading || availableDates < 1 || isBooked}
            />
          </div>
        </Tooltip>
      </Grid>
      <Grid
        item
        xs={3}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography
          component="span"
          color={
            localQuantity > 0 && availableDates > 0
              ? "text.primary"
              : "text.disabled"
          }
        >
          {renderPrice()}
        </Typography>
      </Grid>
      <Grid
        item
        xs={0.5}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {!isBooked && (
          <Close
            className="sponsorship-close"
            sx={iconStyle}
            fontSize="small"
            onClick={handleRemove}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SponsorshipItem;
