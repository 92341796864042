import { Add, Remove } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

export type CounterProps = {
  value: number;
  onChange: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
};

export default function NumberInput({
  value,
  onChange,
  minValue,
  maxValue,
  disabled,
}: CounterProps) {
  const handleDecrement = () => {
    const newValue =
      minValue !== undefined ? Math.max(value - 1, minValue) : value - 1;
    onChange(newValue);
  };

  const handleIncrement = () => {
    const newValue =
      maxValue !== undefined ? Math.min(value + 1, maxValue) : value + 1;
    onChange(newValue);
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        height: 32,
        alignItems: "center",
        border: "1px solid",
        borderRadius: 1,
        overflow: "hidden",
        borderColor: "grey.200",
      }}
    >
      <IconButton
        size="small"
        onClick={handleDecrement}
        disabled={disabled || value === minValue}
        sx={{
          borderRadius: 0,
          color: "text.secondary",
          borderRight: "1px solid",
          borderColor: "grey.200",
        }}
      >
        <Remove fontSize="small" />
      </IconButton>

      <Box
        sx={{
          width: 48,
          textAlign: "center",
          lineHeight: "40px",
          color: "text.primary",
        }}
      >
        {value}
      </Box>

      <IconButton
        size="small"
        onClick={handleIncrement}
        disabled={disabled || value === maxValue}
        sx={{
          borderRadius: 0,
          color: "text.secondary",
          borderLeft: "1px solid",
          borderColor: "grey.200",
        }}
      >
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
}
