import { MoreVert } from "@mui/icons-material";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { noop } from "lodash";
import { MouseEvent, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon, SuccessMessageDialog } from "shared-components";
import { Paper } from "../../../../../components/MUIWrappers";
import { NewInvite } from "../../types";
import MediaPlanNameTextfield from "./MediaPlanNameTextfield";
import MediaPlanShareModal from "./MediaPlanShare";
import SponsorshipRangeSelector from "./SponsorshipRangeSelector";
import { MediaPlanContext } from "../../context";

export default function MediaPlanInfo() {
  const { isBooked } = useContext(MediaPlanContext)!;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const { mediaPlanId } = useParams();

  const handleClose = () => setInviteOpen(false);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSendInvitations = async (invites: NewInvite[]) => {
    try {
      const requestBody = {
        first_name: invites.map((invite) => invite.firstName),
        last_name: invites.map((invite) => invite.lastName),
        email: invites.map((invite) => invite.email),
      };
      await axios.post(
        `/api/v1/advertiser/media_plans/${mediaPlanId}/invite_collaborators`,
        requestBody
      );
      setShowSuccessDialog(true);
    } catch (error) {
      noop();
    } finally {
      handleClose();
    }
  };

  const withMenuClose = (action: () => void) => () => {
    action();
    handleMenuClose();
  };

  const menuItems = [
    {
      icon: <Icon name="invite" width={20} height={20} />,
      text: "Invite",
      onClick: withMenuClose(() => setInviteOpen(true)),
      disabled: isBooked,
    },
    // TODO: waiting for API
    // {
    //   icon: <Icon name="listAltCheck" width={20} height={20} />,
    //   text: "Download Creative Requirements",
    //   onClick: withMenuClose(() => {
    //     // TODO: handle "Download Creative Requirements"
    //   }),
    // },
    // {
    //   icon: <Icon name="fileSave" width={20} height={20} />,
    //   text: "Export Media Plan",
    //   onClick: withMenuClose(() => {
    //     // TODO: handle "Export Media Plan"
    //   }),
    // },
  ];

  return (
    <>
      <Paper sx={{ p: "16px", pb: { xs: "0px", md: "16px" } }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <MediaPlanNameTextfield />
          </Grid>
          <Grid item xs={12} md={4} gap={1} display="flex">
            <SponsorshipRangeSelector />
            <Button
              sx={{ minWidth: 40, width: 40 }}
              variant="outlined"
              onClick={handleMenuOpen}
            >
              <MoreVert />
            </Button>
          </Grid>
          <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "8px",
                },
              },
            }}
          >
            {menuItems.map(({ icon, text, onClick, disabled }) => (
              <MenuItem key={text} onClick={onClick} disabled={disabled}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        <MediaPlanShareModal
          open={inviteOpen}
          onClose={handleClose}
          onSendInvitations={handleSendInvitations}
        />
      </Paper>
      {showSuccessDialog && (
        <SuccessMessageDialog
          messageTitle="Invitation Sent Successfully"
          messageDescription="Your collaborator will receive the details to join the media plan."
          onClose={() => setShowSuccessDialog(false)}
        />
      )}
    </>
  );
}
