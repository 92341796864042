import React from "react";
import {
  Drawer,
  Stack,
  Typography,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Action, Actions } from "../Dialogs/ConfirmationDialog";

interface CommonDrawerProps {
  open: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  actions?: Action[];
  anchor?: "left" | "right" | "top" | "bottom";
  width?: string | number;
  dividers?: boolean;
}

const CommonDrawer: React.FC<CommonDrawerProps> = ({
  open,
  onClose,
  title,
  children,
  actions,
  anchor = "right",
  width = { xs: "100%", sm: 588 },
  dividers = false,
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": { width },
      }}
    >
      {title && (
        <Stack
          px="24px"
          py="12px"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          boxShadow="0px 4px 12px 0px #EEE7F780"
          borderBottom={dividers ? "1px solid" : "none"}
          borderColor={dividers ? "divider" : "transparent"}
        >
          <Typography variant="h6">{title}</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
      )}

      <Box flex={1} overflow="auto" display="flex" flexDirection="column">
        {children}
      </Box>

      {actions && actions.length > 0 && (
        <>
          {dividers && <Divider />}
          <Stack
            direction={{ xs: "column", sm: "row" }}
            gap="12px"
            px={2}
            py="12px"
          >
            <Actions actions={actions} />
          </Stack>
        </>
      )}
    </Drawer>
  );
};

export default CommonDrawer;
