import {
  MediaPlanData,
  MediaPlanResponseData,
  Profile,
  SponsorshipOption,
} from "./types";

export function prepareMediaPlan(
  response: MediaPlanResponseData
): MediaPlanData {
  return {
    discountPercent: response.discount_percent,
    discountPrice: response.discount_price,
    name: response.name,
    profiles: response.profiles.map<Profile>((profile) => ({
      id: profile.id,
      slug: profile.slug,
      logo: profile.logo,
      name: profile.name,
      sponsorshipOptions: profile.sponsorship_options.map<SponsorshipOption>(
        (sponsorshipOption) => ({
          id: sponsorshipOption.id,
          uuid: sponsorshipOption.uuid,
          description: sponsorshipOption.description,
          name: sponsorshipOption.name,
          price: sponsorshipOption.price,
          quantity: sponsorshipOption.quantity,
          regularPrice: sponsorshipOption.regular_price,
          availableDates: sponsorshipOption.available_dates,
        })
      ),
    })),
    regularPrice: response.regular_price,
    totalPrice: response.total_price,
    startDate: response.from_date,
    endDate: response.to_date,
    isBooked: response.booked,
  };
}
