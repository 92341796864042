import { Tooltip as TooltipMUI, TooltipProps } from "@mui/material";

export function Tooltip(props: TooltipProps) {
  return (
    <TooltipMUI
      {...props}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: "black",
            fontSize: "14px",
            fontWeight: 400,
            p: "12px",
          },
        },
        arrow: {
          sx: {
            color: "black",
          },
        },
      }}
      sx={{
        ...props.sx,
      }}
    />
  );
}
